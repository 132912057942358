import React from "react";

export default function X() {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2025_1587)">
				<mask
					id="mask0_2025_1587"
					style={{ maskType: "luminance" }}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="16"
					height="16">
					<path
						d="M0 0H16V16H0V0Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_2025_1587)">
					<path
						d="M12.6 0.749725H15.0537L9.69372 6.89144L16 15.2503H11.0629L7.19314 10.1817L2.77029 15.2503H0.314286L6.04686 8.67887L0 0.750868H5.06286L8.55543 5.38287L12.6 0.749725ZM11.7371 13.7783H13.0971L4.32 2.14515H2.86171L11.7371 13.7783Z"
						fill="currentColor"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_2025_1587">
					<rect
						width="16"
						height="16"
						fill="white"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
